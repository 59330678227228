import axios from "axios";
import { MessageBox, Message } from "element-ui"
import { handleRefreshToken } from '@/utils/api/index';
import router from '@/router'
import { v4 as uuidv4 } from 'uuid';
import qs from 'qs';
import { crcLang } from './index'
/**
 * https://blog.csdn.net/qq_38595560/article/details/103529205
 */

const i18nList = JSON.parse(localStorage.getItem('i18nList'))
// i8nList 初null

// 创建 axios 实例
let baseURL = process.env.VUE_APP_BASE_URL + process.env.VUE_APP_BASE_API
// let baseURL = "http://localhost:8054"
// if (process.env.NODE_ENV === "production") {
//   // 生产环境
//   baseURL = process.env.VUE_APP_BASE_URL + '/buyer';
// } else if (process.env.NODE_ENV === "development") {
//   // 开发环境
//   baseURL = '/buyer';
// }


// 本地 export const commonUrl = process.env.NODE_ENV === 'development' ? 'http://192.168.1.7:8890' : process.env.VUE_APP_BASE_URL;
export const commonUrl = process.env.NODE_ENV === 'development' ? process.env.VUE_APP_BASE_URL : process.env.VUE_APP_BASE_URL;
// export const commonUrl = process.env.NODE_ENV === 'development' ? 'http://192.168.0.5:8056/common' : process.env.VUE_APP_BASE_URL;
// 创建axios实例
let isRefreshToken = 0;
let banRepetition = true;//避免重复弹窗
const refreshToken = getTokenDebounce();
const service = axios.create({
  baseURL: baseURL,
  timeout: 30000, //请求超时时间
  paramsSerializer: params =>
    qs.stringify(params, {
      arrayFormat: 'repeat'
    })
});

// 请求拦截器
service.interceptors.request.use(config => {
  // 如果是put/post请求，用qs.stringify序列化参数
  const isPutPost = config.method === 'put' || config.method === 'post';
  const isJson = config.headers['Content-Type'] === 'application/json';
  // const isFile = config.headers['Content-Type'] === 'multipart/form-data';
  if (isPutPost && isJson) {
    config.data = JSON.stringify(config.data);
  }
  // if (isPutPost && !isFile && !isJson) {
  //   config.data = qs.stringify(config.data, {
  //     arrayFormat: 'repeat'
  //   });
  // }

  let uuid = localStorage.getItem('uuid');
  if (!uuid) {
    uuid = uuidv4();
    localStorage.setItem('uuid', uuid);
  }
  // console.log(uuid);
  config.headers['uuid'] = uuid;
  // 获取访问Token
  let accessToken = localStorage.getItem('accessToken');
  if (accessToken && config.needToken !== false) {
    config.headers['accessToken'] = accessToken;
    // 解析当前token时间
    let jwtData = JSON.parse(
      decodeURIComponent(escape(window.atob(accessToken.split('.')[1].replace(/-/g, '+').replace(/_/g, '/'))))
    );
    if (jwtData.exp < Math.round(new Date() / 1000)) {
      refresh(config)
    }
  }
  return config;
},
  error => {
    Promise.reject(error);
  }
);

// 换取token
async function refresh(error) {
  const getTokenRes = await refreshToken();
  // console.log(getTokenRes)
  if (getTokenRes === 'success') {
    // 刷新token
    console.log(isRefreshToken)
    if (isRefreshToken === 1) {
      error.response.config.headers.accessToken = localStorage.getItem(
        'accessToken'
      );
      return service(error.response.config);
    } else {
      router.go(0);
    }
  } else {
    localStorage.removeItem('userInfo')
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
    if (banRepetition) {
      banRepetition = false;
      MessageBox.confirm(i18nList.tipsLogin, i18nList.tipsTitle, {
        distinguishCancelAndClose: true,
        confirmButtonText: i18nList.nowLogin,
        cancelButtonText: i18nList.browse,
        type: 'warning'
      }).then(res => {
        if (res == 'confirm') {
          router.push({
            path: '/login',
            query: {
              rePath: router.history.current.path,
              query: JSON.stringify(router.history.current.query)
            }
          });
          banRepetition = true;
        }
      }).catch(() => {
        banRepetition = true;
        router.push("/login");
        // console.log(action)
      });
    }
  }
}

// 响应拦截器
service.interceptors.response.use((response) => {
  if (response.data.code === 400) {
    return Message.error(crcLang(response.data.message));
  }
  return response.data;
}, (error) => {
  if (error.server) return Promise.reject(error);
  const errorResponse = error.response || {};
  const errorData = errorResponse.data || {};

  if (errorResponse.status === 401 || errorResponse.status === 403 || error.response.data.code === 20004) {
    isRefreshToken++;

    if (isRefreshToken === 1) {
      refresh(error)
      isRefreshToken = 0;
    }
  } else if (errorResponse.status === 404) {
    // 避免刷新token时也提示报错信息
  } else {
    if (error.message) {
      // let _message =
      //   error.code === 'ECONNABORTED'
      //     ? i18nList.errCode1
      //     : i18nList.errCode2;
      // 返回错误信息提示
      Message.error(crcLang(errorData.message));
    }
  }
  return Promise.resolve(error.response.data);
});

// 刷新token
function getTokenDebounce() {
  let lock = false;
  let success = false;
  return function () {
    if (!lock) {
      lock = true;
      let oldRefreshToken = localStorage.getItem('refreshToken');
      handleRefreshToken(oldRefreshToken)
        .then(res => {
          // console.log(res, oldRefreshToken)
          if (res.success) {
            let { accessToken, refreshToken } = res.result;
            localStorage.setItem('accessToken', accessToken);
            localStorage.setItem('refreshToken', refreshToken);

            success = true;
            lock = false;
          } else {
            success = false;
            lock = false;
            // router.push('/login')
          }
        })
        .catch(err => {
          console.log(err);
          success = false;
          lock = false;
        });
    }
    return new Promise(resolve => {
      // 一直看lock,直到请求失败或者成功
      const timer = setInterval(() => {
        if (!lock) {
          clearInterval(timer);
          if (success) {
            resolve('success');
          } else {
            resolve('fail');
          }
        }
      }, 500); // 轮询时间间隔
    });
  };
}


export default function request(options) {
  return service(options);
}
// export default service;
