import Vue from 'vue';
import VueRouter from 'vue-router';
import HomeView from '../views/Home.vue';

Vue.use(VueRouter);

//解决vue路由重复导航错误
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push;
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};
// 解决报错
const originalReplace = VueRouter.prototype.replace;
// push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(err => err);
};
// replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch(err => err);
};

const routes = [
  {
    path: '/home',
    name: 'home',
    component: HomeView,
  },
  // 重定向if / 指向home
  // {
  //   path: "/",
  //   redirect: '/home'
  // },
  {
    path: '/',
    redirect: '/user',
  },
  {
    path: '/integration',
    name: 'Integration',
    component: () => import('../views/Integration.vue'),
  },
  {
    // 订单列表
    path: '/orderList',
    name: 'OrderList',
    component: () => import('../views/OrderList.vue'),
  },
  {
    // 询价
    path: '/inquiry',
    name: 'Inquiry',
    component: () => import('../views/Inquiry.vue'),
  },
  {
    // 商城
    path: '/list',
    name: 'List',
    component: () => import('../views/GoodsList.vue'),
  },
  {
    // 在线商城
    path: '/mall',
    name: 'Mall',
    component: () => import('../views/user/components/mall.vue'),
  },
  {
    // 商品详情
    path: '/order/goodsInfo',
    name: 'GoodsInfo',
    component: () => import('../views/order/GoodsInfo.vue'),
  },
  {
    // 订单详情
    path: '/orderDetail',
    name: 'OrderDetail',
    component: () => import('../views/order/OrderDetail.vue'),
  },
  {
    // 订单支付
    path: '/pay',
    name: 'Pay',
    component: () => import('../views/order/Pay.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/login/Login.vue'),
  },
  {
    // 修改密码
    path: '/changePassword',
    name: 'ChangePassword',
    component: () => import('../views/login/ChangePassword.vue'),
  },
  {
    // 忘记密码
    path: '/forgotPassword',
    name: 'ForgotPassword',
    component: () => import('../views/login/ForgotPassword.vue'),
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/login/Register.vue'),
  },
  {
    path: '/authentication',
    name: 'Authentication',
    component: () => import('../views/login/Authentication.vue'),
  },
  {
    path: '/invite',
    name: 'Invite',
    component: () => import('../views/login/Invite.vue'),
  },
  {
    path: '/user',
    children: [
      {
        // 个人中心首页
        path: '/',
        name: 'User',
        component: () => import('../views/user/components/UserPersonal.vue'),
      },
      {
        // 我的订单
        path: '/payList',
        name: 'PayList',
        component: () => import('../views/user/components/PayList.vue'),
      },
      {
        // 我的收藏
        path: '/collect',
        name: 'Collect',
        component: () => import('../views/user/components/Collect.vue'),
      },
      {
        // 我的订单
        path: '/myOrder',
        name: 'MyOrder',
        component: () => import('../views/user/components/MyOrder.vue'),
      },
      {
        // 我的订单详情
        path: '/myOrderDetail',
        name: 'MyOrderDetail',
        component: () => import('../views/user/components/MyOrderDetail.vue'),
      },
      {
        // 优惠券
        path: '/coupon',
        name: 'Coupon',
        component: () => import('../views/user/components/Coupon.vue'),
      },
      {
        // 地址管理
        path: '/addressList',
        name: 'AddressList',
        component: () => import('../views/user/components/AddressList.vue'),
      },
      {
        // 消息中心
        path: '/message',
        name: 'Message',
        component: () => import('../views/user/components/Message.vue'),
      },
      {
        // 个人信息
        path: '/infoList',
        name: 'InfoList',
        component: () => import('../views/user/components/InfoList.vue'),
      },
      {
        // 订单
        path: '/myOrder',
        name: 'MyOrder',
        component: () => import('../views/user/components/MyOrder.vue'),
      },
      {
        // 支付详情
        path: '/payDetail',
        name: 'PayDetail',
        component: () => import('../views/user/components/PayDetail.vue'),
      },
      {
        // 店铺入驻
        path: '/applyShop',
        name: 'ApplyShop',
        component: () => import('../views/user/components/ApplyShop.vue'),
      },
      {
        // 编辑用户信息
        path: '/userEdit',
        name: 'UserEdit',
        component: () => import('../views/user/components/UserEdit.vue'),
      },
      {
        // 工单
        path: '/service',
        name: 'Service',
        component: () => import('../views/user/components/Service.vue'),
      },
      {
        // 工单详情
        path: '/serviceDetail',
        name: 'ServiceDetail',
        component: () => import('../views/user/components/ServiceDetail.vue'),
      },
      {
        // 我的订阅
        path: '/subscription',
        name: 'Subscription',
        component: () => import('../views/user/components/Subscription.vue'),
      },
      {
        // 佣金明细
        path: '/commission',
        name: 'Commission',
        component: () => import('../views/user/components/Commission.vue'),
      },
    ],
    component: () => import('../views/user/User.vue'),
  },
  {
    path: '/formList',
    name: 'FormList',
    component: () => import('../views/user/FormList.vue'),
  },
  // 使用者条款
  {
    path: '/termosUser',
    name: 'TermosUser',
    component: () => import('../views/termos/TermosUser.vue'),
  },

  // 使用者条款
  {
    path: '/termosPagamento',
    name: 'TermosPagamento',
    component: () => import('../views/termos/TermosPagamento.vue'),
  },

  // 使用者条款
  {
    path: '/termosPrivacy',
    name: 'TermosPrivacy',
    component: () => import('../views/termos/TermosPrivacy.vue'),
  },
  {
    path: '/termosRefund',
    name: 'TermosRefund',
    component: () => import('../views/termos/TermosRefund.vue'),
  },
  // 使用者条款
  {
    path: '/TermosPay',
    name: 'TermosPay',
    component: () => import('../views/termos/TermosPay.vue'),
  },
  {
    path: '/cart',
    name: 'Cart',
    component: () => import('../views/order/Cart.vue'),
  },
  {
    path: '/test',
    name: 'test',
    component: () => import('../views/demo/test.vue'),
  },
];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.NODE_ENV === 'test' ? '/test' : process.env.BASE_URL,
  routes,
});

// 路由守卫
let nextRoute = ["Mall", 'User', 'List', 'OrderList', 'PayList', 'Collect', 'AddressList', 'Message', 'InfoList', 'MyOrder', 'PayDetail', 'UserEdit', 'Service', 'ServiceDetail', 'Coupon'];
router.beforeEach((to, from, next) => {
  // 判断是否是登录中
  let userInfo = JSON.parse(localStorage.getItem('userInfo'));
  // console.log('未登录', nextRoute, to.name)
  // 验证是否为需要验证的页面
  if (nextRoute.indexOf(to.name) >= 0) {
    // 如果未登录跳转登录
    if (!userInfo) {
      router.push('/login');
    }
    // if (userInfo?.isLevel === 0 && !userInfo.isShopifyIn) {
      // 非超级用户+未入住店铺
      // router.push('/applyShop');
      // if (!userInfo.isShopifyIn) {
      //   router.push('/applyShop');
      // }
    // }
  }
  next();
});

export default router;
